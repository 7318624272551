import competitorLists from '../../Assets/managefeisGrade/competitorList.svg';
import addCompetitor from '../../Assets/managefeisGrade/addCompetitor.svg';
import editCompetitor from '../../Assets/managefeisGrade/editCompetitor.svg';
import mergeSplit from '../../Assets/managefeisGrade/mergesplit.svg';
import directLink from '../../Assets/managefeisGrade/directLink.svg';
import email from '../../Assets/managefeisGrade/email.svg';
import eventDetails from '../../Assets/managefeisGrade/eventDetails.svg';
import accommodation from '../../Assets/managefeisGrade/accommodation.svg';
import syllabus from '../../Assets/managefeisGrade/syllabus.svg';
import musicians from '../../Assets/managefeisGrade/musicians.svg';
import sponsors from '../../Assets/managefeisGrade/sponsors.svg';
import checkin from '../../Assets/managefeisGrade/checkin.svg';
import publishedResults from '../../Assets/managefeisGrade/publishedResults.svg';
import results from '../../Assets/managefeisGrade/results.svg';
import scoreCheckin from '../../Assets/managefeisGrade/scoreCheckin.svg';
import schedule from '../../Assets/managefeisGrade/schedule.svg';
import scheduleMaker from '../../Assets/managefeisGrade/schedulemaker.svg';
import uploadComp from '../../Assets/managefeisGrade/uploadSchedule.svg';
import downloadComp from '../../Assets/managefeisGrade/downloadCompetitions.svg';

export const manageFeisArray = [
  {
    mainTitle: 'Entries',
    details: [
      {
        logo: competitorLists,
        title: 'Competitor Lists',
        description: 'Full list of registered competitors',
        isModal: false,
        path: '/competitorss',
        name: true,
        id: true,
        cardClickEnabled: true,
      },
      {
        logo: addCompetitor,
        title: 'Add a Competitor',
        description: 'Create a new entry',
        isModal: true,
        modal: 'AddCompetitor',

      },
      {
        logo: editCompetitor,
        title: 'Edit Competitors',
        description: 'Add / Change an existing Entry',
        isModal: false,
        path: '/edit-competitors',
        name: true,
        id: true,

      },
      {
        logo: mergeSplit,
        title: 'Merges and Splits',
        description: 'Combine or Split Competitions',
        isModal: false,
        path: '/merge-split',
        name: true,
        id: true,

      },
      {
        logo: directLink,
        title: 'Direct Link',
        description: 'Create a Link for Late Entries',
        isModal: true,
        modal: 'DirectLink',

      },
      {
        logo: email,
        title: 'Email',
        description: 'Send an email to all registered competitors',
        isModal: false,
        path: '/email',
        id: true,
        cardClickEnabled: true,
        from: 'feis',
      },
    ],
  },
  {
    mainTitle: 'Event',
    details: [
      {
        logo: eventDetails,
        title: 'Event Details',
        description: 'Location, Date, etc.',
        isModal: false,
        path: '/event-details',
        // name: true,
        id: true,
        cardClickEnabled: true,
      },
      {
        logo: mergeSplit,
        title: 'Adjudicators',
        description: 'Add or Edit Adjudicators',
        isModal: true,
        modal: 'Adjudicators',
        cardClickEnabled: true,

      },
      {
        logo: accommodation,
        title: 'Accommodation',
        description: 'Add or Edit Hotel Info',
        onClick: '',
        isModal: true,
        modal: 'Accomodation',
        cardClickEnabled: true,

      },
      {
        logo: syllabus,
        title: 'Syllabus',
        description: 'Make Changes to the Syllabus',
        isModal: false,
        path: '/organizer-create-feisanna',
        tab: 5,

      },
      {
        logo: musicians,
        title: 'Musicians',
        description: 'Add or Edit Musicians',
        isModal: true,
        modal: 'Musicians',
        cardClickEnabled: true,

      },
      {
        logo: sponsors,
        title: 'Sponsors',
        description: 'Add or Edit Sponsors',
        isModal: true,
        modal: 'Sponsors',
        cardClickEnabled: true,

      },
    ],
  },
  {
    mainTitle: 'Tabulation',
    details: [
      {
        logo: checkin,
        title: 'Check-in',
        description: 'Side Stage Check-in',
        isModal: false,
        path: '/check-in',
        name: true,
        id: true,

      },
      {
        logo: mergeSplit,
        title: 'Tabulate',
        description: 'Enter Results Day Of',
        isModal: false,
        path: '/tabulate',
        name: true,
        id: true,
        // cardClickEnabled: true,
      },
      {
        logo: publishedResults,
        title: 'Results Status',
        description: 'View Published Status of Results ',
        isModal: false,
        path: '/result-status',
        name: true,
        id: true,
        // cardClickEnabled: true,
      },
      {
        logo: results,
        title: 'Published Results',
        description: 'View Completed Results',
        isModal: false,
        path: '/published-results',
        name: true,
        id: true,
        cardClickEnabled: true,
      },
      {
        logo: eventDetails,
        title: 'Awards Table',
        description: 'View/Manage Awards Table',
        isModal: false,
        path: '/awards',
        name: false,
        id: true,
        // cardClickEnabled: true,
      },
    ],
  },
  {
    mainTitle: 'Print',
    details: [
      {
        logo: scoreCheckin,
        title: "Score and Check-in Sheets",
        description: "Print all, by level, or Individual",
        isModal: false,
        path: "/score-checkin-download",
        name: true,
        id: true,

      },
      {
        logo: mergeSplit,
        title: 'Number Cards',
        description: 'Print all or Individual',
        isModal: false,
        path: '/competitorss',
        name: true,
        id: true,

      },
      {
        logo: schedule,
        title: 'Schedule',
        description: 'Print Schedules',
        onClick: '',
        cardClickEnabled: true,
      },
    ],
  },
  {
    mainTitle: 'Schedule',
    details: [
      {
        logo: scheduleMaker,
        title: 'Schedule Maker',
        description: 'Create or Edit the Schedule',
        // isModal: true,
        // modal: 'ScheduleMaker',
        // path: "/manage-schedule",
        // id: true
        schedule: true
      },
      {
        logo: uploadComp,
        title: 'Upload Schedule',
        description: 'Upload PDF Schedule',
        onClick: '',
        upload: true,
      },
      {
        logo: downloadComp,
        title: 'Download Competitions',
        description: 'Export a CSV of all competitions',
        isModal: false,
        downloadCsv: true,
        cardClickEnabled: true,


      },
    ],
  },
  {
    mainTitle: 'Financial',
    details: [
      {
        logo: scheduleMaker,
        title: 'Stripe Account',
        description: 'Stripe Account',
        isModal: false,
        path: '/organizer-account',
        name: false,
        id: true,
        cardClickEnabled: true,
      },
      {
        logo: uploadComp,
        title: 'Fees',
        description: 'View / Edit Family and Late Fees',
        isModal: false,
        path: '/organizer-create-feisanna',
        tab: 4,
        // cardClickEnabled: true,
      },
      {
        logo: uploadComp,
        title: "Transactions",
        description: "View Transactions",
        isModal: false,
        path: "/transaction-history-org",
        name: true,
        id: true,
        cardClickEnabled: true,
      },
    ],
  },
];
