import React, { useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Radio from '../../../../Components/Radio/Radio';
import filterIcon from "../../../../Assets/filter.svg";
import { groupAndSortComp } from '../../../../helper/SortSyllabus';
import { getAgeValue } from './scheduleHelpers';

const ScheduleTableFilters = ({ competitions, setCompetitions, selected, setSelected }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        const sortingFunctions = {
            sortAscending,
            sortDescending,
            ascendAgeWise,
            descendAgeWise,
            ascendLevelWise,
            descendLevelWise
        };

        const selectedSortingFunction = sortingFunctions[selected];
        if (selectedSortingFunction) {
            selectedSortingFunction();
        }
    }, [competitions.length, selected]);

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const sortAscending = () => {
        setSelected("sortAscending");
        const sortedCompetitions = [...competitions].sort((a, b) => a.code.localeCompare(b.code));
        setCompetitions(sortedCompetitions);
    };

    const sortDescending = () => {
        setSelected("sortDescending");
        const sortedCompetitions = [...competitions].sort((a, b) => b.code.localeCompare(a.code));
        setCompetitions(sortedCompetitions);
    };

    const ascendAgeWise = () => {
        setSelected("ascendAgeWise");
        const sortedCompetitions = [...competitions].sort(
            (a, b) =>
                getAgeValue(a?.ages[a?.ages?.length - 1]) -
                getAgeValue(b?.ages[b?.ages?.length - 1])
        );
        setCompetitions(sortedCompetitions);
    };

    const descendAgeWise = () => {
        setSelected("descendAgeWise");
        const sortedCompetitions = [...competitions].sort(
            (a, b) =>
                getAgeValue(b?.ages[b?.ages?.length - 1]) -
                getAgeValue(a?.ages[a?.ages?.length - 1])
        );

        setCompetitions(sortedCompetitions);
    };

    const ascendLevelWise = () => {
        setSelected("ascendLevelWise");
        const sortedCompetitions = groupAndSortComp(competitions);
        setCompetitions(sortedCompetitions);
    };

    const descendLevelWise = () => {
        setSelected("descendLevelWise");
        const sortedCompetitions = groupAndSortComp(competitions, true);
        setCompetitions(sortedCompetitions);
    };

    const classNameString = "ps-3 py-2 px-3 d-flex align-items-center justify-content-between create-event-opt";

    return (
        <>
            <img className="cursor-pointer" src={filterIcon} alt="filter" onClick={handleFilterClick} />
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ marginTop: '5px', marginLeft: 0 }}
            >
                <div className={classNameString} onClick={ascendAgeWise}>
                    Age - Low to High
                    <Radio className="mb-3" checked={selected === "ascendAgeWise"} />
                </div>
                <div className={classNameString} onClick={descendAgeWise}>
                    Age - High to Low
                    <Radio className="mb-3" checked={selected === "descendAgeWise"} />
                </div>
                <div className={classNameString} onClick={ascendLevelWise}>
                    Level - Low to High
                    <Radio className="mb-3" checked={selected === "ascendLevelWise"} />
                </div>
                <div className={classNameString} onClick={descendLevelWise}>
                    Level - High to Low
                    <Radio className="mb-3" checked={selected === "descendLevelWise"} />
                </div>
            </Popover >
        </>
    );
}

export default ScheduleTableFilters;
