import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import HeaderOrg from '../Headers/HeaderOrg';
import SingleLineTitle from '../../Components/TitleElements/SingleLineTitle';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import { Container } from 'react-bootstrap';
import StripeDescription from '../StripeComponents/StripeDescription';
import AddStripeAccount from '../StripeComponents/AddStripeAccount';
import StripeCard from '../StripeComponents/StripeCard';
import DeleteStripeAccount from '../StripeComponents/DeleteStripeAccount';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const StripeAccount = () => {
  const [loading, setloading] = useState(false);
  const [cards, setCards] = useState()
  const [errorconn, seterrorconn] = useState('');
  const [codeProcessing, setCodeProcessing] = useState(true);


  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const eventId = searchParams?.get('id');

  if (eventId) localStorage.setItem("ID", eventId)


  useEffect(() => {
    if (!codeProcessing) getCardDetails()
  }, [codeProcessing]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // const successParam = searchParams.get("success")
    const codeParam = searchParams.get("code")//got on link account
    if (codeParam) {
      searchParams.delete("code")
      const newUrl = `${location.pathname}`;
      window.history.replaceState(null, "", newUrl);
      sendCodeToBackend(codeParam)
    } else {
      setCodeProcessing(false)
    }
  }, [location.search, location.pathname,]);

  const sendCodeToBackend = async (code) => {
    setloading(true)
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stripe/link-connect-account`
      const response = await serviceConsumer("POST", url, { code })
      if (response?.success) {
        window.location.reload();
      }
      setloading(false)
    } catch (err) {
      setloading(false)
    } finally {
      setCodeProcessing(false);
    }
  }


  const getCardDetails = async () => {
    setloading(true)
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stripe/list-connect-accounts`;
      const response = await serviceConsumer('POST', url);
      setCards(response?.result)
    }
    catch (err) { }
    setloading(false)
  }

  //formik submit handler
  const viewStripeHandler = async (cardId) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stripe/account/login/${cardId}`;
      const response = await serviceConsumer('GET', url);
      window.location.href = response.result.url;
    } catch (error) { }
  };

  const goBackToManagePage = () => {
    const id = localStorage.getItem("ID")
    const manageType = localStorage.getItem("Manage")
    if (manageType === "feis") navigate(`/organizer-manage-feis?id=${id}&Manage=${manageType}`)
    else navigate(`/organizer-manage-grade?id=${id}&Manage=${manageType}`)
  }

  return (
    <>
      {loading && <Loader />}
      <HeaderOrg from={'stripe'} />
      <SingleLineTitle titleName="Stripe Account" onBackClick={goBackToManagePage} />
      <Container className="stripeContainer">
        <div>
          <StripeDescription />
          <AddStripeAccount cards={cards} seterrorconn={seterrorconn} setLoading={setloading} />
          {cards?.map((card, index) => (
            card.externalAccount.currency && card.externalAccount.last4 && !errorconn && (
              <div key={index} className='d-flex align-items-center gap-3 mt-3'>
                <StripeCard card={card} />
                <DeleteStripeAccount card={card} getCardDetails={getCardDetails} />
                <div onClick={() => viewStripeHandler(card?._id)} style={{ color: "#68AD3F", cursor: "pointer", marginTop: "2px" }}>
                  <VisibilityOutlinedIcon />
                </div>
              </div>
            )
          ))}
        </div>
        <ToastContainer />
      </Container>
    </>
  );
};

export default StripeAccount;
