import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import HeaderOrg from '../../../Headers/HeaderOrg'
import SingleLineTitle from '../../../../Components/TitleElements/SingleLineTitle'
import Help from '../../../../Components/Help/Help'
import AddButton from '../../../../Components/AddButton/AddButton'
import { serviceConsumer } from '../../../../network/ServiceConsumer'
import favicon from "../../../../Assets/favicon.ico"
import StagesTable from './StagesTable'
import ScheduleTableFilters from './ScheduleTableFilters'
import { openOrCloseModal } from '../../../../helper/CreateDispatches'
import AddAStageModal from '../../../../Components/Modals/OrganizerModel/ManageFeis/ScheduleMaker/AddAStageModal'
import AddedCompetitor from '../../../../Components/Modals/OrganizerModel/ManageFeis/Entries/AddedCompetitorModal'
import Loader from '../../../../Components/Loader/Loader'
import { ShowToastMessage } from '../../../../helper/ShowToastMessage'
import { downloadCSV, formatDataForCSV, getSchedulePayload } from './scheduleHelpers'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { exportToExcel } from './exportToExcel'

const ManageSchedule = () => {
    const [event, setEvent] = useState()
    const [competitions, setCompetitions] = useState([])
    const [scheduleList, setScheduleList] = useState([])
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState({});
    const [allCompetitions, setAllCompetitions] = useState([])
    const [saved, setSaved] = useState(false)
    const [isPublished, setIsPublished] = useState(false)

    const modal = useSelector((state) => state.modalReducer)
    const dispatch = useDispatch()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const eventId = searchParams.get('id');
    const [filterComp, setFilterComp] = useState([])

    const setFilteredComp = (comp, table = tableData) => {
        const excludedCompetitionIds = new Set(
            Object.entries(table)
                .filter(([key]) => key !== "Events_Events")
                .flatMap(([_, data]) => data.map(competition => competition.competitionId))
        );

        const resultCompetitions = comp.filter(
            item => !excludedCompetitionIds.has(item.competitionId)
        );
        setCompetitions(resultCompetitions);
    }

    useEffect(() => {
        const excludedCompetitionIds = new Set(
            Object.entries(tableData)
                .filter(([key]) => key !== "Events_Events")
                .flatMap(([_, data]) => data.map(competition => competition.competitionId))
        );

        const resultCompetitions = allCompetitions.filter(
            item => !excludedCompetitionIds.has(item.competitionId)
        );
        setFilterComp(resultCompetitions)
    }, [tableData])



    let searchAndFilterDiv = <div>
        <input key="search" type="text" placeholder="Search here" className="scheduleInput" onChange={(e) => filterCompetitionsHandler(e)} />
        {<ScheduleTableFilters competitions={filterComp} setCompetitions={setFilteredComp} />}
    </div>

    const evokeFilteredComp = (table) => {
        setFilteredComp(allCompetitions, table)
    }

    useEffect(() => {
        try {
            getEventDetails();
            // to filter competitions based on list get list first and then filter competitions
            // getList()
            getCompetitions()
                .then(res => getList())
                .catch(err => console.log(err))
        } catch (err) {
            console.log(err)
        }

    }, []);

    useEffect(() => {
        setTableData(prevData => ({
            ...prevData,
            Events_Events: [{ competitionId: searchAndFilterDiv, code: searchAndFilterDiv }, { competitionId: "Break Time", code: "Break Time" }, ...competitions]
        }));
    }, [competitions]);

    useEffect(() => {
        const scheduleListCopy = [...scheduleList]
        const transformedList = {
            Events_Events: [{ competitionId: searchAndFilterDiv, code: searchAndFilterDiv }, { competitionId: "Break Time", code: "Break Time" }, ...competitions],
            ...scheduleListCopy.reduce((acc, { stageName, _id, timings }) => {
                const key = `${stageName}_${_id}`; // Concatenate id and stageName to form the key
                acc[key] = timings?.map(item => {
                    if (item.type === "break")
                        return { ...item, code: "Break Time" }
                    else
                        return { ...item, dancerCount: item?.competitionDetails?.dancerCount, rounds: item?.competitionDetails?.rounds }
                })
                return acc;
            }, {})
        };
        setTableData(transformedList)
        setTimeout(() => setFilteredComp(allCompetitions, transformedList), 500)
    }, [scheduleList]);


    const getCompetitions = async () => {
        try {
            // const url = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}/competition`
            const url = `${process.env.REACT_APP_BASE_URL}/schedule-maker/list-competition/${eventId}`
            const response = await serviceConsumer("GET", url)
            // the other apis has competitionId as key. so to make keys common, we do this
            const competitionsList = response?.data?.competitions?.map((competition) => { return { ...competition, competitionId: competition?._id } })
            //filter competitions such that they are not present in the stages
            // setFilteredComp(competitionsList)
            setAllCompetitions(competitionsList)
        } catch (error) {
        }
    }
    const getList = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/schedule-maker/list-schedules`
        const payload = {
            eventId: eventId
        }
        try {
            setLoading(true)
            const response = await serviceConsumer("POST", url, payload)
            const schList = response?.result?.map(item => {
                if (Object.keys(item.timings[0]).length === 0) {
                    return {
                        ...item, // Spread the original item properties
                        timings: [] // Add or initialize timings property with an empty array
                    };
                } else return item
            })
            setIsPublished(response?.isPublished)
            setScheduleList(schList)
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    const getEventDetails = async () => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
            const response = await serviceConsumer('GET', url);
            setEvent(response?.Feis)
        } catch (err) {

        }
    }

    const openAddAStageModal = () => {
        openOrCloseModal(dispatch, "AddAStageModal", eventId)
    }

    const addStageHandler = (stageName) => {
        setTableData(prev => ({ ...prev, [stageName]: [] }))
    }

    function filterCompetitionsHandler(e) {
        const search = e.target.value.toLowerCase();
        if (search === '') {
            setFilteredComp(allCompetitions)
        } else {
            const filteredCompetitions = allCompetitions.filter(item => item?.code?.toLowerCase().includes(search));
            setFilteredComp(filteredCompetitions)
        }
    }


    const updateChangesHandler = async () => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/schedule-maker/update-schedule`
            const data = getSchedulePayload(tableData)
            const payload = {
                eventId: eventId,
                data: data,
            }
            const isStageEmpty = data.some(item => item.timings.length === 0)
            if (isStageEmpty) {
                ShowToastMessage("error", "Stage cannot be empty")
            } else {
                await serviceConsumer("POST", url, payload)
                setSaved(true)
                ShowToastMessage("success", "Schedule saved successfully")
                await getList()
                await getCompetitions()
            }
            setLoading(false)
        }
        catch (err) {
            setLoading(false)
            ShowToastMessage("error", err?.messages || "something went wrong")
        }
    }
    const publishHandler = async (status) => {
        const publishOrUnpublish = status === "publish" ? "publish-schedule" : "unpublish-schedule"
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/schedule-maker/${publishOrUnpublish}`
            const payload = { eventId: eventId }

            const data = getSchedulePayload(tableData)
            const isStageEmpty = data.some(item => item.timings.length === 0)
            if (!isPublished && isStageEmpty) {
                ShowToastMessage("error", "Stage cannot be empty")
            } else {
                await serviceConsumer("POST", url, payload)
                setIsPublished(!isPublished)
                setSaved(false)
                status === "publish" ? successModalHandler() : ShowToastMessage("success", "Schedule unpublished")
            }
            setLoading(false)
        } catch (err) {
            ShowToastMessage("error", err?.messages || "something went wrong")
            setLoading(false)
        }
    }

    const successModalHandler = () => {
        openOrCloseModal(dispatch, "successModal", eventId)
        setTimeout(() => openOrCloseModal(dispatch, "", eventId), 2000)
    }

    const exportCSVHandler = () => {
        exportToExcel(tableData, `Schedule-${event?.name}.xlsx`)
    }

    return (
        <>
            {loading && <Loader />}
            <HeaderOrg />
            <SingleLineTitle titleName="Schedule Maker" />
            <div className="org-body-white">
                <div className="d-flex gap-2 align-items-center justify-content-between mt-3">
                    <div className="d-flex gap-2">
                        <img className="manage-title-img" src={event?.logo ? event?.logo : favicon} alt="" />
                        <div className="org-body-title">{event?.name}</div>
                    </div>
                    <div>
                        <AddButton className="plusIconbtn" onClick={openAddAStageModal} >Add stage</AddButton>
                    </div>
                </div>
                <div className="mb-3 hr-org-body" />
                <StagesTable tableData={tableData} setTableData={setTableData} eventId={eventId} evokeFilteredComp={evokeFilteredComp} />
                <div className="d-flex flex-grow-1 justify-content-end mt-3 mb-5 gap-3">
                    <button className='outlined' onClick={exportCSVHandler}>Export to CSV</button>
                    {!isPublished && <button className='outlined' onClick={updateChangesHandler} >Save</button>}
                    {isPublished
                        ? <button className="outlined" onClick={() => publishHandler("unpublish")}> Unpublish</button>
                        : <button className={saved ? "filled" : "disabled"} onClick={() => publishHandler("publish")}>Publish</button>}
                </div>
            </div>
            <Help />
            {modal.modal === "AddAStageModal" && <AddAStageModal modalState={true} addStage={addStageHandler} eventId={eventId} tableData={tableData} />}
            {modal.modal === "successModal" && <AddedCompetitor modalState={true} schedule={true} />}
        </>
    )
}

export default ManageSchedule
