import React, { useState, useEffect } from 'react'
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import Help from '../../../../Components/Help/Help'
import { serviceConsumer } from '../../../../network/ServiceConsumer'
import { useLocation } from 'react-router-dom'
import HeaderOrg from '../../../Headers/HeaderOrg'
import "../FeisCompetitor.css"
import Loader from '../../../../Components/Loader/Loader'
import SingleLineTitle from '../../../../Components/TitleElements/SingleLineTitle'
import edit from "../../../../Assets/edit-comp-action.svg"
import sort from "../../../../Assets/managefeisGrade/sort-org.svg"
import { groupAndSortComp, sortCompetitions } from '../../../../helper/SortSyllabus'

import { useNavigate } from 'react-router-dom'
import ConfirmDeleteModal from '../../../../Components/Modals/OrganizerModel/ManageFeis/Tabulate/ConfirmDeleteModal';
import { ShowToastMessage } from '../../../../helper/ShowToastMessage';

const EditCompetitors = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const eventId = searchParams.get('id');
    const [responseArray, setResponseArray] = useState([])
    const [tableArr, setTableArr] = useState([])
    const [sorterData, setSorterData] = useState("")
    const [sortOrder, setSortOrder] = useState("asc")
    const [loading, setLoading] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [dancerToBeDeleted, setDancerToBeDeleted] = useState()
    const [err, setErr] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const navigate = useNavigate()

    const eventName = location?.state?.eventName

    //on page load get competitors
    const getCompetitors = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}/dancers`;
        try {
            setLoading(true)
            const response = await serviceConsumer("GET", url)
            setResponseArray(response?.result)
            setLoading(false)
        }
        catch (error) {
            navigate("/pageNotFound", { replace: true })
            setLoading(false)
        }
    }
    useEffect(() => {
        getCompetitors()
    }, [])

    // search input in competitors
    useEffect(() => {
        const tableArr = responseArray?.filter((searching) => {
            if (searchInput === "") {
                return searching;
            } else if (
                (searching?.dancerData?.fname?.toLowerCase().includes(searchInput?.toLowerCase())
                    || searching?.dancerData?.lname?.toLowerCase().includes(searchInput?.toLowerCase())
                    || searching?.dancerData?.school?.toLowerCase().includes(searchInput?.toLowerCase())
                    || searching?.dancerData?.birthday?.toString().includes(searchInput)
                    || searching?.card_number?.toString().includes(searchInput)
                    || searching?.competitonData?.map((item) => item?.code).toString().toLowerCase().includes(searchInput.toLowerCase()))
            ) {
                return searching;
            }
        })
        setTableArr(tableArr)
    }, [responseArray, searchInput])

    //sort competitor as per column
    const sortCompetitorsHandler = (sorter) => {
        let order = sorter === sorterData ? (sortOrder === "asc" ? "desc" : "asc") : "asc";
        order === "asc" && sorter === "id" && tableArr.sort((a, b) => a.card_number - b.card_number)
        order === "asc" && sorter === "competitor" && tableArr.sort((a, b) => `${a.dancerData?.fname} ${a.dancerData.lname}`.localeCompare(`${b.dancerData?.fname} ${b.dancerData.lname}`))
        order === "asc" && sorter === "dob" && tableArr.sort((a, b) => a.dancerData.birthday - (b.dancerData.birthday))
        order === "asc" && sorter === "competitions" && tableArr.sort((a, b) => (a.competitonData[0].code.localeCompare(b.competitonData[0].code)))

        order === "desc" && sorter === "id" && tableArr.sort((a, b) => b.card_number - a.card_number);
        order === "desc" && sorter === "competitor" && tableArr.sort((a, b) => `${b.dancerData?.fname} ${b.dancerData.lname}`.localeCompare(`${a.dancerData?.fname} ${a.dancerData.lname}`));
        order === "desc" && sorter === "dob" && tableArr.sort((a, b) => b.dancerData.birthday - (a.dancerData.birthday));
        order === "desc" && sorter === "competitions" && tableArr.sort((a, b) => (b.competitonData[0].code.localeCompare(a.competitonData[0].code)))

        setTableArr(tableArr)
        setSorterData(sorter)
        setSortOrder(order)
    }
    //edit icon handler
    const editClickHandler = (dancer) => {
        navigate(`/edit-register/${eventName}?id=${eventId}&regId=${dancer._id}`)
    }

    const deleteClickHandler = (dancer) => {
        setDancerToBeDeleted(dancer)
        setOpenModal(true)
    }

    const deleteDancerHandler = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/remove-dancer`;
        const payload = {
            eventId: eventId,
            dancerId: dancerToBeDeleted?.dancerData?._id
        }
        setLoading(true)
        try {

            const response = await serviceConsumer("POST", url, payload)
            closeModalHandler()
            ShowToastMessage("success", response?.message)
            getCompetitors()
        } catch (err) {
            ShowToastMessage("error", err?.messages)
        } finally {
            setLoading(false)
        }

    }

    const closeModalHandler = () => {
        setDancerToBeDeleted("")
        setOpenModal(false)
    }

    const inputHandler = (e) => {
        setErr("");
        setSearchInput(e.target.value)
    }



    return (
        <>
            {loading && <Loader />}
            <HeaderOrg />
            <SingleLineTitle titleName="Edit Competitor" />
            <div className="org-body-white">
                <div className="d-flex gap-2 align-items-center mt-3">
                    <div className="org-body-title">Competitors</div>
                    <div className="competitor-count ">{tableArr.length}</div>
                </div>
                <div className="mb-3 hr-org-body" />
                <div className="search-label" style={{ marginTop: "33px" }}>Search:</div>
                <div >
                    <input type="text" value={searchInput} onChange={inputHandler} placeholder="Search..." className="searchFeisComp searchFeisComps" />
                </div>
                <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }}>
                    <table className="table my_table competitorTable ">
                        <tbody>
                            <tr className="header-row">
                                <td className=" mx-5 w-10 p-5-25">#&nbsp;<span>
                                    <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler("id")} />
                                </span></td>
                                <td className="mx-5 w-25 p-25-5">Competitor&nbsp;<span>
                                    <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler("competitor")} />
                                </span></td>
                                <td className="w-10 p-25-5">DOB&nbsp;<span>
                                    <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler("dob")} />
                                </span></td>
                                <td className="w-25 p-25-5">Competitions&nbsp;<span>
                                    <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler("competitions")} />
                                </span></td>
                                <td className="w-10 p-25-5 text-center">Action</td>
                            </tr>

                            {tableArr.map((dancer) => {
                                return <tr style={{ fontSize: "12px", height: "61px" }}>
                                    <td className=" mx-5 w-10 grey-30 p-5-25" >{dancer?.card_number}</td>
                                    <td className="mx-5 w-25 grey-30" ><div>{dancer?.dancerData?.fname}&nbsp;{dancer?.dancerData?.lname}<div style={{ opacity: "0.3" }}>{dancer?.dancerData?.school}</div></div></td>
                                    <td className="w-10 grey-30" >{dancer?.dancerData?.birthday}</td>
                                    <td className="w-25 grey-30">{dancer?.competitonData && groupAndSortComp(dancer?.competitonData).map((item, index) => { return (dancer?.competitonData?.length === index + 1) ? item?.code : `${item?.code}, ` })}</td>
                                    <td className="w-10 text-center">
                                        {dancer?.dancerData?.status
                                            ?
                                            <>
                                                <img onClick={() => editClickHandler(dancer)} style={{ cursor: "pointer" }} src={edit} alt="edit" />
                                                <DeleteOutlineOutlinedIcon
                                                    style={{ color: "red", marginLeft: "10px", cursor: "pointer" }}
                                                    onClick={() => deleteClickHandler(dancer)}
                                                />
                                            </>
                                            : <span style={{ color: "red" }} > Deleted Dancer</span>}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    {!loading && responseArray.length > 0 && tableArr.length < 1 && <div className="d-flex justify-content-center my-5">There are no competitors matching your search</div>}
                    {!loading && responseArray.length === 0 && <div className="d-flex justify-content-center my-5">There are no competitors registered for this event</div>}
                    {!loading && err && <div className="d-flex justify-content-center my-5 error-class">{err}</div>}
                </div>
            </div >
            <ConfirmDeleteModal modalState={openModal} confirm={deleteDancerHandler} cancel={closeModalHandler} dancerPage={true} />
            <Help />
        </>
    )
}

export default EditCompetitors