import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Card from "../../Components/CardOrg/Card"
import moment from 'moment';

import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import PaginationNew from '../../Components/Pagination/PaginationNew';

//get all events and filter closed events with respect to date
const Closed = () => {
    const [loading, setLoading] = useState(false)
    const [feis, setFeis] = useState([])
    const [grade, setGrade] = useState([])
    const prevFeisPage = localStorage.getItem("prevFeisPage") ? localStorage.getItem("prevFeisPage") : "1"
    const [pageFeis, setPageFeis] = useState(prevFeisPage);
    const [totalPagesFeis, setTotalPagesFeis] = useState(0);
    const [startIndexFeis, setStartIndexFeis] = useState(0)
    const [endIndexFeis, setEndIndexFeis] = useState(0)
    const prevGradePage = localStorage.getItem("gradePage") ? localStorage.getItem("gradePage") : "1"
    const [pageGrade, setPageGrade] = useState(prevGradePage);
    const [totalPagesGrade, setTotalPagesGrade] = useState(0);
    const [startIndexGrade, setStartIndexGrade] = useState(0)
    const [endIndexGrade, setEndIndexGrade] = useState(0)

    const navigate = useNavigate()
    //navigations
    const navigateToResultsPage = (event) => {
        navigate(`/published-results/${event?.name}?id=${event?._id}`)
    }

    //get closed events of type feiseanna
    const getClosedFeis = async () => {
        try {
            setLoading(true)
            let payload = {
                page: pageFeis,
                limit: 5,
                date: moment(new Date()).utc(true).format(),
                type: "DASHBOARD"
            }
            const url = `${process.env.REACT_APP_BASE_URL}/feis/organizer/feis/closed`;
            const response = await serviceConsumer("POST", url, payload)
            let totalData = response?.totalCount;
            let totalPage = Math.ceil(Number(totalData / 5))
            setTotalPagesFeis(totalPage)
            let startindex = pageFeis % 10 === 0 ? pageFeis - 9 : (Math.floor(pageFeis / 10)) * 10 + 1
            setStartIndexFeis(startindex)
            startindex + 9 > totalPage ? setEndIndexFeis(totalPage) : setEndIndexFeis(startindex + 9)
            if (response?.Feis?.results?.length > 0) {
                let data = response?.Feis?.results;
                setFeis(data);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    //get closed events of type grade
    const getClosedGrade = async () => {
        try {
            setLoading(true)
            let payload = {
                page: pageGrade,
                limit: 5,
                date: moment(new Date()).utc(true).format(),
            }
            const url = `${process.env.REACT_APP_BASE_URL}/feis/organizer/grade/closed`;
            const response = await serviceConsumer("POST", url, payload)
            let totalData = response?.totalCount;
            let totalPage = Math.ceil(Number(totalData / 5))
            setTotalPagesGrade(totalPage)
            let startindex = pageGrade % 10 === 0 ? pageGrade - 9 : (Math.floor(pageGrade / 10)) * 10 + 1
            setStartIndexGrade(startindex)
            startindex + 9 > totalPage ? setEndIndexGrade(totalPage) : setEndIndexGrade(startindex + 9)
            if (response?.Feis?.results?.length > 0) {
                let data = response?.Feis?.results;
                setGrade(data);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getClosedFeis()
    }, [pageFeis])
    useEffect(() => {
        getClosedGrade()
    }, [pageGrade])
    return (
        <div className="db-body">
            {loading && <Loader />}
            {feis.length > 0 && <><div className="db-org-title pt-4">Fiesanna </div>
                <div className="mt-1 hr-org-body" />
                {feis.map(event => <Card logo={event?.logo}
                    eventType={event?.eventType}
                    eventName={event?.name}
                    date={event?.feis_date}
                    venue={event?.venue_city}
                    state={event?.state}
                    onClick={() => navigateToResultsPage(event)}
                    btnName="Results" />)}
            </>}
            <PaginationNew
                loading={loading}
                startIndex={startIndexFeis}
                endIndex={endIndexFeis}
                totalPages={totalPagesFeis}
                page={pageFeis}
                setPage={val => setPageFeis(val)}
                setStartIndex={val => setStartIndexFeis(val)}
                setEndIndex={val => setEndIndexFeis(val)}
                dataPerPage={5}
                event="prevFeisPage"
            />
            {!loading && feis.length === 0 && grade.length === 0 && <div className=" py-5 text-center">No closed events.</div>}
            {grade.length > 0 && <><div className="db-org-title pt-4">Grade exam </div>
                <div className="mt-1 hr-org-body" />
                {grade.map(event =>
                    <Card logo={event?.logo}
                        eventType={event?.eventType}
                        eventName={event?.name}
                        date={event?.exam_date}
                        venue={event?.venue_city}
                        state={event?.state}
                    />)}
            </>}
            <PaginationNew
                loading={loading}
                startIndex={startIndexGrade}
                endIndex={endIndexGrade}
                totalPages={totalPagesGrade}
                page={pageGrade}
                setPage={val => setPageGrade(val)}
                setStartIndex={val => setStartIndexGrade(val)}
                setEndIndex={val => setEndIndexGrade(val)}
                dataPerPage={5}
                event="prevGradePage"
            />
        </div>
    )
}

export default Closed