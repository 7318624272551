import React, { useState, useEffect } from 'react';
import SingleLineTitle from '../../../../Components/TitleElements/SingleLineTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../../../../Components/Button/Button';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import Loader from '../../../../Components/Loader/Loader';
import moment from 'moment';
import Help from '../../../../Components/Help/Help';
import HeaderOrg from '../../../Headers/HeaderOrg';
import { formatDate } from '../../../../helper/formatDate';
import { getClosed } from '../../../../helper/setLocalStorage';
const EventDetails = () => {
  const [event, setEvent] = useState();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get('id');
  const ManageType = localStorage.getItem('Manage');
  const [showUI, setShowUI] = useState(false)

  useEffect(() => {
    setLoading(true);
    getEventDetail();
  }, []);

  const navigate = useNavigate();
  const editButtonHandler = () => {
    localStorage.setItem('TAB', 1);
    localStorage.setItem('ID', eventId);
    localStorage.setItem("redirectFeis", "edit")
    ManageType == 'feis' ? navigate('/organizer-create-feisanna') : navigate('/organizer-create-grade');
  };

  //get event by eventId for event details
  const getEventDetail = async () => {
    setShowUI(true)
    try {
      const url =
        ManageType == 'feis'
          ? `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
          : `${process.env.REACT_APP_BASE_URL}/grade-exam/${eventId}`;
      const response = await serviceConsumer('GET', url);
      ManageType == 'feis' ? setEvent(response.Feis) : setEvent(response.grade_exams);
      setLoading(false);
    } catch (error) {
      // navigate("/pageNotFound", { replace: true })
      setLoading(false);
    }
  };

  const backClickHandler = () => {
    ManageType == 'feis' ? navigate(`/organizer-manage-feis?id=${eventId}&Manage=feis`) : navigate(`/organizer-manage-grade?id=${eventId}&Manage=grade`)
  }

  return (
    <div>
      {loading && <Loader />}
      <HeaderOrg />
      <SingleLineTitle titleName="Manage event" onBackClick={backClickHandler} />
      <div className="org-body-white">
        {!loading && showUI && (
          <>
            <div className="d-flex justify-content-between mt-3">
              <div>
                <div className="org-body-title">Event Details</div>
                <span style={{ fontSize: '16px', color: ' #424242;' }}>Complete these details to create event.</span>
              </div>
              <div className="d-flex align-items-end">
               {!getClosed()&& <Button className="filled" onClick={editButtonHandler}>Edit</Button>}
              </div>
            </div>
            <div className="hr-org-body mt-1" />
            <div className="d-md-flex mt-5 gap-5" style={{ marginBottom: '100px' }}>
              <div className="col-md-6 ">
                <div className="org-body-title">Event Details</div>
                <div className="hr-org-body mt-1" />

                <div className="search-label mt-3 mx-0">{event?.eventType === 'Grade Exam' ? 'Name of Exam:' : 'Name of Feis:'}</div>
                <div className="feis-manage">{event?.name}</div>

                <div className="search-label mt-3 mx-0">
                  {event?.eventType === 'Grade Exam' ? 'Exam Contact Email:' : 'Feis Contact Email:'}
                </div>
                <div className="feis-manage">{event?.contact_email}</div>

                <div className="search-label mt-3 mx-0">Region:</div>
                <div className="feis-manage">{event?.region}</div>

                <div className="search-label mt-3 mx-0">{event?.eventType === 'Grade Exam' ? 'Exam Date:' : 'Feis Date:'}</div>
                <div className="feis-manage">
                  {moment(formatDate(event?.eventType === 'Grade Exam' ? event?.exam_date : event.feis_date)).format('MM/DD/YYYY')}
                </div>

                <div className="search-label mt-3 mx-0">Reg. Open Date:</div>
                <div className="feis-manage">
                  {moment(formatDate(event?.opening_date)).format('MM/DD/YYYY')}
                </div>

                <div className="search-label mt-3 mx-0">Reg. Close Date:</div>
                <div className="feis-manage">
                  {moment(formatDate(event?.closing_date)).format('MM/DD/YYYY')}
                </div>
              </div>

              <div className="col-md-6">
                <div className="org-body-title mt-5 mt-md-0">Venue Details</div>
                <div className="hr-org-body mt-1" />

                <div className="search-label mt-3 mx-0">Venue Name:</div>
                <div className="feis-manage">{event?.venue_name}</div>

                <div className="search-label mt-3 mx-0">Address:</div>
                <div className="feis-manage">
                  <div>{event?.venue},</div>
                  <div>{event?.venue_city},</div>
                  <div>{event?.region},</div>
                  <div>{event?.country}-{event?.postal_code}.</div>
                </div>
              </div>
            </div>
          </>
        )}
        {!loading && err && (<div className="d-flex justify-content-center" style={{ color: 'red', fontSize: '12px' }} > {err}</div>)}
      </div>
      <Help />
    </div>
  );
};

export default EventDetails;
