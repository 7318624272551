import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PAYMENT_METHOD } from "../../redux/actionTypes/authActionType";
import DancerLevelData from "./DancerLevelData";
import { serviceConsumer } from "../../network/ServiceConsumer";
import { ToastContainer, toast } from "react-toastify";
import { getEditServiceFee, getFamilyFess, getServiceFee } from "./helper";
import moment from "moment";
import { formatDate } from "../../helper/formatDate";

const RegisterInFiesanaaEvent = (props) => {
  const {
    prevTotal,
    selectedLevelData,
    eventDetails,
    allDancer,
    deleteItemHandler,
    setshowPaymentPage,
    clientSecret,
    errormsg,
    prevFamilyFee,
    edit,
    regId,
    previousCompetition,
    prevDancerTotal,
    deleted,
    deletedEventRegistrationHandler,
    regBtnEnable
  } = props;

  const [paymentArray, setPaymentArray] = useState([]);
  const [enableButton, setEnableButton] = useState(false);
  const [lateFee, setLateFee] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [purchaseTotal, setPurchaseTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [familyFee, setFamilyFee] = useState(0);
  const isTeacherString = localStorage.getItem("isTeacher")
  const isTeacher = isTeacherString === 'true';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    if (!isTeacher && eventDetails && eventDetails?.fees && eventDetails?.fees?.length > 0 && eventDetails?.fees[0]?.lateFee && eventDetails?.fees[0]?.lateFee?.afterDate) {
      let lateFeeDate = moment(formatDate(eventDetails.fees[0].lateFee.afterDate));
      let currentDate = moment(formatDate(new Date()));
      if (lateFeeDate < currentDate) {
        setLateFee(eventDetails.fees[0]?.lateFee?.additionalFee);
      }
    }
    if (eventDetails) {
      if (eventDetails.registered || isTeacher) setFamilyFee(0)
      else setFamilyFee(getFamilyFess(eventDetails))
    }
  }, [eventDetails]);

  useEffect(() => {
    if (eventDetails && selectedLevelData && !deleted) {
      getPaymentArray()
    }
  }, [selectedLevelData]);

  useEffect(() => {
    if (eventDetails && selectedLevelData && !deleted) {
      getPurchaseTotal()
    }
  });


  const getPaymentArray = () => {
    let arr = [];
    selectedLevelData.forEach((item) => {
      let data = {};

      const amount = [];
      let dancerTotal = 0;

      if (item?.levelPriceData) {
        Object.keys(item.levelPriceData)?.forEach(level => {
          item.levelPriceData[level]?.forEach(priceData => {
            amount.push(priceData.price)
            dancerTotal += priceData.price
          })
        })

        const dancerServiceAmount = Math.round(dancerTotal / 10);

        let allLevelAmount = dancerTotal;

        // dancer total without service fee
        dancerTotal = dancerTotal + ((edit && !isTeacher) ? prevFamilyFee : Number(familyFee))

        let serviceFee = getServiceFee(dancerTotal);

        dancerTotal = dancerTotal + serviceFee

        data.amount = amount;
        data.competitions = item?.competitions;
        data.dancer_id = item?._id;
        data.dancerServiceAmount = dancerServiceAmount;
        data.dancerTotal = dancerTotal;
        data.allLevelAmount = allLevelAmount
        data.serviceFee = serviceFee
        arr.push(data);
      }
    });

    setPaymentArray(arr);
  }

  // calculate purchase total of selected event
  const getPurchaseTotal = () => {
    const { amount, newItemSelected, selected, specialAmount, amountBeforeFamilyMax } = getAmountOfLevelData();

    // update subTotal anf purchaseTotal after service fee added
    updateTotals(amount, selected, specialAmount, amountBeforeFamilyMax)

    if (!newItemSelected) {
      if (count !== previousCompetition?.length && count > 0) setEnableButton(true);
      else setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  }

  // calculate amount of all selected level wise competition
  const getAmountOfLevelData = () => {
    let amount = 0;
    let newItemSelected = false;
    let selected = false
    let selectedItem = 0

    // calculating separate total price of TEAMS, SPECIALS, second_chance for adding it after everything
    let specialAmount = 0;
    let amountBeforeFamilyMax = 0

    selectedLevelData?.forEach((item) => {
      if (item?.levelPriceData) {
        selected = true;
        Object.keys(item?.levelPriceData)?.forEach(level => {
          return item?.levelPriceData[level]?.forEach((item) => {
            amount += item.price
            amountBeforeFamilyMax += item.price
            selectedItem += 1
            if (['TEAMS', 'SPECIALS'].includes(item.group) || item.second_chance === true) {
              specialAmount += item.price
            }
            if (!previousCompetition?.includes(item._id) && item) newItemSelected = true;
          })
        });
      }
    });

    setCount(selectedItem)

    return { amount, newItemSelected, selected, specialAmount, amountBeforeFamilyMax }
  }

  // updated state for total amount and other fees
  const updateTotals = (value, selected, specialAmount, amountBeforeFamilyMax) => {
    let amount = value;
    const checkPrice = specialAmount > 0 ? (amount - specialAmount) : amount
    const familyMax = eventDetails?.fees[0]?.familymax;

    if (checkPrice > familyMax && !edit) {
      // familyFee max will not impact edit flow
      amount = familyMax + specialAmount;
      setSubTotal(amountBeforeFamilyMax)
    } else {
      setSubTotal(0)
    }

    amount = addServiceFee(amount)

    if (selected) setPurchaseTotal(amount);
    else setPurchaseTotal(0)
  }

  // adding service fee to purchase total
  const addServiceFee = (value) => {
    let amount = value;
    if (!edit) {
      // adding family fee before service fee for purchase total in register
      amount = amount + familyFee;
      // calculate purchase total service fee
      let serviceAmount = eventDetails.registered ? getEditServiceFee(amount) : getServiceFee(amount);
      setServiceFee(serviceAmount);
      amount = amount + serviceAmount
    } else {
      const newTotal = amount - prevTotal;
      let newServiceFee = newTotal <= 0 ? 0 : getEditServiceFee(newTotal);
      setServiceFee(newServiceFee);
      amount = (newTotal) + newServiceFee
      amount = amount <= 0 ? 0 : amount
    }

    return amount;
  }

  const getDancerTotalPrice = (dancer) => {
    let total = 0;
    Object.keys(dancer?.levelPriceData)?.forEach(level => dancer?.levelPriceData[level]?.forEach((item) => total += item.price));
    return total;
  }

  const getDanceName = (dancerId) => {
    const dancer = allDancer?.find(item => item._id === dancerId);
    return `${dancer?.fname} ${dancer?.lname}`;
  }

  const paymentButtonHandler = async () => {
    setEnableButton(false);
    dispatch({
      type: "EVENT_DETAILS",
      payload: {
        eventType: eventDetails.eventType,
        eventName: eventDetails.name,
        eventDate: eventDetails?.feis_date,
        eventImage: eventDetails?.logo,
      },
    });

    let paymentObj = {
      totalAmount: edit ? purchaseTotal + prevDancerTotal : purchaseTotal,
      serviceAmount: edit ? paymentArray[0].serviceFee : serviceFee,
      ...(edit
        ? {
          dancer_id: selectedLevelData[0]._id,
          registration_id: regId,
          competitions: paymentArray[0].competitions,
          amount: paymentArray[0].amount,
          allLevelAmount: paymentArray[0].allLevelAmount,
          dancerTotal: purchaseTotal + prevDancerTotal,
          dancerServiceAmount: paymentArray[0].dancerServiceAmount,
        }
        : {
          event_id: eventDetails._id,
          org_id: eventDetails.organizer_id,
          platformAmount: serviceFee || '0',
          lateFee: lateFee,
          data: paymentArray,
          fee: familyFee
        }),
    };
    paymentObj.data = paymentObj?.data?.filter(obj => obj.competitions.length !== 0)
    setshowPaymentPage(true);
    try {
      const url = edit ? `${process.env.REACT_APP_BASE_URL}/stripe/edit/checkout` : `${process.env.REACT_APP_BASE_URL}/stripe/checkout`;
      const response = await serviceConsumer('POST', url, paymentObj);

      if (response.success === 'Payment_Pending' || response.success === true) {
        clientSecret(response.checkout?.client_secret);
        if (purchaseTotal) {
          dispatch({
            type: PAYMENT_METHOD,
            payload: {
              showPaymentPage: true,
              client: response.checkout?.client_secret,
              errors: "",
            },
          });
          edit ? navigate("/checkout", {
            state: {
              from: "editFies",
              paymentDetails: {
                showPaymentPage: true,
                client: response.checkout?.client_secret,
                errors: "",
              },
              eventDetails: {
                eventType: eventDetails.eventType,
                eventName: eventDetails.name,
                eventDate: eventDetails?.feis_date,
                eventImage: eventDetails?.logo,
              }
            }
          }) : navigate("/checkout", {
            state: {
              from: "fiesReg",
              paymentDetails: {
                showPaymentPage: true,
                client: response.checkout?.client_secret,
                errors: "",
              },
              eventDetails: {
                eventType: eventDetails.eventType,
                eventName: eventDetails.name,
                eventDate: eventDetails?.feis_date,
                eventImage: eventDetails?.logo,
              }
            }
          });
        } else {
          edit
            ? navigate("/parent/feis/edit-register/payment/success", { state: { from: edit ? "editFeis" : "fiesReg", name: eventDetails?.name } })
            : navigate("/parent/feis/register/payment/success", { state: { from: edit ? "editFeis" : "fiesReg", name: eventDetails?.name } });
        }
      }
    } catch (error) {
      errormsg(error?.messages);
      toast.error(error?.messages);
      setEnableButton(false)
      dispatch({
        type: PAYMENT_METHOD,
        payload: {
          showPaymentPage: false,
          client: "",
          errors: error.messages,
        },
      });
    }
  };

  return (
    <>
      {eventDetails ? (
        <div className="col-lg-7 d-flex flex-column flex-grow-1 offset-1 col-10">
          <div className="flex-grow-1">
            <h5
              className="mt-5 pb-2 mb-3"
              style={{ borderBottom: "1px solid #DDD3D0 ", color: "#424242" }}
            >
              Register in:
            </h5>
            {/* <hr /> */}
            {selectedLevelData?.map((dancer, index) => {
              if (dancer?.competitions?.length) {
                return (
                  <React.Fragment key={index}>
                    {dancer && (
                      <>
                        {dancer ? <div className="d-flex justify-content-between align-items-center py-2 my-2">
                          <div className="dancerNameHeading">
                            {getDanceName(dancer._id)}
                          </div>
                          {!deleted && dancer?.levelPriceData && <div className="dancerFeeBtn">
                            ${getDancerTotalPrice(dancer)}
                          </div>}
                        </div> : <></>}
                        {dancer.levelPriceData && (
                          <DancerLevelData
                            index={index}
                            dancer={dancer}
                            levelPriceData={dancer.levelPriceData}
                            deleteItemHandler={deleteItemHandler}
                            deleted={deleted}
                          />
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              }
            })}
            {!deleted&& <div >
              {!edit && !isTeacher  && (prevFamilyFee !== undefined || familyFee !== 0) && <div className="d-flex justify-content-between align-items-center">
                <div className=" my-3c reg-name-font">Family</div>
                <div className=" my-3 reg-amt">
                  ${prevFamilyFee || familyFee}
                </div>
              </div>}
              {serviceFee > 0 && <div className="d-flex justify-content-between align-items-center">
                <div className=" my-3 reg-name-font">Service</div>
                <div className=" my-3 reg-amt">${serviceFee?.toFixed(2)}</div>
              </div>}

              {lateFee > 0 && <div className="d-flex justify-content-between align-items-center">
                <div className=" my-3 reg-name-font">Late Fee</div>
                <div className=" my-3 reg-amt">${lateFee}</div>
              </div>}
            </div>}
          </div>
          {!deleted && <div>
            <hr />

            {subTotal && selectedLevelData?.length > 0 ? (
              <div className="d-flex justify-content-between">
                <div className=" my-3" style={{ fontSize: "30px", color: "#424242" }}>
                  SubTotal:
                </div>
                <div className=" my-3 reg-total">
                  ${subTotal?.toFixed(2)}
                </div>
              </div>
            ) : null}

            <div className="d-flex justify-content-between">
              <div className=" my-3" style={{ fontSize: "30px", color: "#424242" }}>
                Total:
              </div>
              <div className=" my-3 reg-total">
                ${purchaseTotal && count > 0 ? purchaseTotal?.toFixed(2) : 0}
              </div>
            </div>

            <div>
              <button
                className={
                  (enableButton && count > 0) ? "purchase-btn mb-5" : "purchase-btn-disabled mb-5"
                }
                disabled={!(enableButton && count > 0)}
                onClick={paymentButtonHandler}
              >
                Continue to payment
              </button>
            </div>
          </div>}
          {deleted && <button className={regBtnEnable ? "filled my-3" : "disabled my-3"} onClick={deletedEventRegistrationHandler}> Register</button>}
        </div>
      ) : (
        <div>Loading...</div>
      )}
      <ToastContainer />
    </>
  );
};
export default RegisterInFiesanaaEvent;
