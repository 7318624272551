import moment from "moment";
import { formatDate } from "../../helper/formatDate";

export const getServiceFee = (amount) => {
  let serviceAmount = 0;
  if (amount <= 100) {
    serviceAmount = 10;
  } else if (amount > 100 && amount <= 200) {
    serviceAmount = Math.round(amount / 10);
  } else serviceAmount = 20;

  return serviceAmount;
}

export const getEditServiceFee = (amount) => {
  return Number(Number((amount / 20).toFixed(2)));
}

export const getFamilyFess = (eventDetails) => {
  if (eventDetails) {
    const familyLess = eventDetails.fees[0]?.earlyFee?.familyLess;
    const lateFee = eventDetails.fees[0]?.lateFee?.additionalFee;

    const lateFeeAfterClosed = eventDetails.fees[0]?.lateFeeAfterClosed?.additionalFee;

    let familyfee = eventDetails.fees[0]?.familyfee;

    if (moment() <= moment(formatDate(eventDetails.fees[0]?.earlyFee?.ifDateIsBefore))) {
      familyfee -= familyLess
    }

    if ((moment() > moment(formatDate(eventDetails.fees[0]?.lateFee?.afterDate))) ) {
      familyfee += lateFee
    }

    if ((moment() > moment(formatDate(eventDetails.fees[0]?.lateFeeAfterClosed?.afterDate))) ) {
      familyfee += lateFeeAfterClosed + lateFee
    }

    return familyfee;
  }
  return 0;
}