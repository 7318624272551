import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import HeaderOrg from '../../../Headers/HeaderOrg'
import Help from '../../../../Components/Help/Help'
import Loader from '../../../../Components/Loader/Loader'
import { serviceConsumer } from '../../../../network/ServiceConsumer'
import ManageTitle from '../../../../Components/TitleElements/ManageTitle'
import Button from '../../../../Components/Button/Button'
import { getPrintHandler } from '../../../../helper/printHandlers'
import { sortCompetitions } from '../../../../helper/SortSyllabus';
import GoLiveConfirmationModal from '../../../../Components/Modals/OrganizerModel/GoLiveConfirmationModal';


const ScoreCheckInDownload = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const eventId = searchParams.get('id')
    const [event, setEvent] = useState()
    const [titleLoader, setTitleLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [levels, setLevels] = useState([])
    const [scoreLevel, setScoreLevel] = useState("")
    const [checkLevel, setCheckLevel] = useState("")
    const [scoreCompetitions, setScoreCompetitions] = useState([])
    const [checkCompetitions, setCheckCompetitions] = useState([])
    const [scoreCompetitionId, setScoreCompetitionId] = useState("")
    const [checkCompetitionId, setCheckCompetitionId] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [openStageModal, setOpenStageModal] = useState(false)
    const [stages, setStages] = useState([])
    const [selectedStage, setSelectedStage] = useState("")

    //get event by eventId for event details
    const getEventDetail = async () => {
        try {
            setTitleLoader(true)
            const url = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
            const response = await serviceConsumer('GET', url)
            setEvent(response.Feis)
            getLevels()
            setTitleLoader(false)
        }
        catch (error) {
            navigate("/pageNotFound", { replace: true })
            setTitleLoader(false)
        }
    }
    const getStages = async () => {
        try {
            setTitleLoader(true)
            const url = `${process.env.REACT_APP_BASE_URL}/schedule-maker/list-stages/${eventId}`
            const response = await serviceConsumer('GET', url)
            const stagesArr = response?.data?.stages?.map(item => {
                return { name: item?.stageName, id: item?._id }
            })
            setStages(stagesArr)
            setTitleLoader(false)
        }
        catch (error) {
            setTitleLoader(false)
        }
    }

    useEffect(() => {
        getEventDetail()
        getStages()
    }, [])

    //get levels for the event
    const getLevels = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/levels/checkIn`
        const payload = {
            id: eventId,
        }
        try {
            setLoading(true)
            const levelResponse = await serviceConsumer("POST", url, payload)
            setLevels(levelResponse.result)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    }

    // get competitions for level
    const getCompetitions = async (level, checkOrScore) => {
        try {
            setLoading(true)
            const payload = {
                feis_id: eventId,
                level: level
            }
            const url = `${process.env.REACT_APP_BASE_URL}/feis/level/competitions`;
            const response = await serviceConsumer("POST", url, payload)
            const competitionsResponse = response.result;
            const competitionsWithDancers = competitionsResponse.filter(competition => competition.dancers.length > 0)
            const sortedCompetitionsWithDancers = sortCompetitions(competitionsWithDancers)
            checkOrScore === "score" && setScoreCompetitions(sortedCompetitionsWithDancers)
            checkOrScore === "check" && setCheckCompetitions(sortedCompetitionsWithDancers)
            handleCompetitionChange(sortedCompetitionsWithDancers[0]._id, checkOrScore)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    }
    //level select handler
    const handleLevelChange = (event, checkOrScore) => {
        checkOrScore === "score" && setScoreLevel(event.target.value)
        checkOrScore === "check" && setCheckLevel(event.target.value)
        getCompetitions(event.target.value, checkOrScore)
    }

    //competition select handler
    const handleCompetitionChange = (compId, checkOrScore) => {
        checkOrScore === "score" && setScoreCompetitionId(compId)
        checkOrScore === "check" && setCheckCompetitionId(compId)
    }

    //download check-in-sheet handler
    const downloadCheckinSheetHandler = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/checkInSheet/pdf/${checkCompetitionId}`;
        getPrintHandler(url, setLoading)
    }

    const printAllHandler = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/scoresheetAll/pdf/${eventId}`;
        getPrintHandler(url, setLoading, event?.name, setOpenModal)
    }

    //download scoresheet
    const downloadScoreSheetHandler = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/scoresheet/pdf/${scoreCompetitionId}`;
        getPrintHandler(url, setLoading)
    }

    const downloadStageHandler = () => {
        const url = selectedStage === eventId
            ? `${process.env.REACT_APP_BASE_URL}/feis/scoresheet/stage/pdf?eventId=${selectedStage}`
            : `${process.env.REACT_APP_BASE_URL}/feis/scoresheet/stage/pdf?stageId=${selectedStage}`

        getPrintHandler(url, setLoading, event?.name, setOpenStageModal)
    }

    const handleStageChange = (e) => {
        // const stageName = e.target.options[e.target.selectedIndex].text;
        // setSelectedStageName(stageName)
        setSelectedStage(e.target.value)
    }
    const renderSheetSection = (type, selectedLevel, competitions, downloadHandler) => {
        return (
            <div>
                <div className="d-sm-flex justify-content-between ">
                    <div class="org-body-title pt-5">{type === "check" ? "Check-in Sheets" : "Score Sheets"}</div>
                </div>
                <div class="mt-1 hr-org-body"></div>
                <div className="search-label mt-3">Choose level:</div>
                <div className='d-flex gap-3 flex-column flex-md-row'>
                    <select name={`${type}-level`} className="check-in-select" onChange={(e) => handleLevelChange(e, type)}>
                        <option defaultValue hidden>Select</option>
                        {levels?.map((levelOption) => <option value={levelOption}>{levelOption}</option>)}
                    </select>
                    {!selectedLevel && <div><Button className="disabledOutlined">Download PDF</Button></div>}
                </div>
                {selectedLevel && (
                    <>
                        <div className="search-label mt-3">Choose Competition:</div>
                        <div className='d-flex gap-3 flex-column flex-md-row'>
                            <select name={`${type}-competition`} className="check-in-select" onChange={(e) => handleCompetitionChange(e.target.value, type)}>
                                {competitions?.map((item) => <option value={item?._id}>{item?.code}-{item?.group}</option>)}
                            </select>
                            <div><Button className="outlined" onClick={downloadHandler}>Download PDF</Button></div>
                        </div>
                    </>
                )}
                {type === "score" &&
                    <>
                        <div className="search-label mt-3">Choose Stage:</div>
                        <div className='d-flex gap-3 flex-column flex-md-row'>
                            <select name={`${type}-level`} className="check-in-select" onChange={(e) => handleStageChange(e, type)}>
                                <option defaultValue hidden>Select</option>
                                <option value={eventId}>All Stages</option>
                                {stages?.map((stage) => <option value={stage?.id}>{stage?.name}</option>)}
                            </select>
                            <div><Button className={selectedStage ? "outlined" : "disabledOutlined"} onClick={downloadStageHandler}>Download PDF</Button></div>
                        </div>
                    </>
                }
            </div>
        )
    }

    return (
        <>
            {loading && <Loader />}
            {titleLoader && <Loader />}
            <HeaderOrg />
            <ManageTitle eventLogo={event?.logo} eventName={event?.name} eventType={event?.eventType} eventDate={event?.feis_date} loading={titleLoader} textButton="Print All Score Sheets" textBtnHandler={printAllHandler} className="text-btn" />
            <div className="db-body">
                {renderSheetSection("score", scoreLevel, scoreCompetitions, downloadScoreSheetHandler)}
                {renderSheetSection("check", checkLevel, checkCompetitions, downloadCheckinSheetHandler)}
                <ToastContainer />
            </div>
            {openModal && <GoLiveConfirmationModal openModal={openModal} setOpenModal={setOpenModal} from="printAll" />}
            {openStageModal && <GoLiveConfirmationModal openModal={openStageModal} setOpenModal={setOpenStageModal} from="printAll" />}
            <Help />
        </>
    )

}

export default ScoreCheckInDownload