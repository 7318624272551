import React, { useEffect, useState } from "react";
import deleteIcon from "../../Assets/delete.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PAYMENT_METHOD } from "../../redux/actionTypes/authActionType";
import { serviceConsumer } from "../../network/ServiceConsumer";
import { getEditServiceFee, getFamilyFess, getServiceFee } from "./helper";

export default function RegisteredGrade(props) {
  const {
    edit,
    org,
    regId,
    eventDetails,
    removeGrade,
    errormsg,
    setshowPaymentPage,
    clientSecret,
    selectedGrades,
    previousCompetition,
    prevFamilyFee,
    prevTotal,
    prevDancerTotal,
    setEnable,
    setPayload,
    clickPaymentButton
  } = props;
  const [enableButton, setEnableButton] = useState(true);

  const [subTotal, setSubTotal] = useState(0);

  const [familyFee, setFamilyFee] = useState(0);

  const [serviceFee, setServiceFee] = useState(10);
  const [purchaseTotal, setPurchaseTotal] = useState(30);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // need to fix after selecting grades
    setEnableButton(true);
  }, []);

  useEffect(() => {
    if (eventDetails) {
      if (eventDetails.registered) setFamilyFee(0)
      else setFamilyFee(getFamilyFess(eventDetails))
    }
  }, [eventDetails]);

  useEffect(() => {
    org && setEnable(enableButton)
  }, [enableButton])

  useEffect(() => {
    if (clickPaymentButton) {
      paymentButtonHandler();
    }
  }, [clickPaymentButton]);

  //calling stripe api on clicking payment button
  const paymentButtonHandler = async () => {
    let paymentArray = []; // for payload
    selectedGrades?.forEach((item) => {
      if (item?.dancer_id && item.grades.length) {
        const ids = [];
        const amounts = [];
        let dancerTotal = 0;

        item?.grades?.forEach((gradeItem) => {
          ids.push(gradeItem._id);
          amounts.push(gradeItem.grade_fee);
          dancerTotal += gradeItem.grade_fee;
        });

        const dancerServiceAmount = Math.round(dancerTotal / 10);
        const allLevelAmount = dancerTotal;

        dancerTotal =
          dancerTotal +
          (edit ? prevFamilyFee : Number(familyFee));

        let serviceFee = getServiceFee(dancerTotal);

        dancerTotal = dancerTotal + serviceFee;

        paymentArray.push({
          dancer_id: item?.dancer_id,
          grade_exam_fee_id: ids,
          amount: amounts,
          allLevelAmount,
          dancerServiceAmount,
          dancerTotal,
          serviceFee,
        });
      }
    });
    let paymentObj = {
      totalAmount: edit ? purchaseTotal + prevDancerTotal : purchaseTotal,
      serviceAmount: edit ? paymentArray[0].serviceFee : serviceFee,
      ...(edit
        ? {
          dancer_id: selectedGrades[0]?.dancer_id,
          registration_id: regId,
          grade_exam_fee_id: paymentArray[0].grade_exam_fee_id,
          amount: paymentArray[0].amount,
          allLevelAmount: paymentArray[0].allLevelAmount,
          dancerTotal: purchaseTotal + prevDancerTotal,
          dancerServiceAmount: paymentArray[0].dancerServiceAmount,
        }
        : {
          platformAmount: serviceFee,
          data: paymentArray,
          org_id: eventDetails?.organizer_id,
          event_id: eventDetails._id,
          fee: familyFee,
        }),
    };
    if (org) {
      setPayload(paymentObj)
    }
    else {
      setshowPaymentPage(true);
      setEnableButton(false);
      try {
        const url = edit
          ? `${process.env.REACT_APP_BASE_URL}/stripe/edit/grade/checkout`
          : `${process.env.REACT_APP_BASE_URL}/stripe/grade/checkout`;
        const response = await serviceConsumer("POST", url, paymentObj);

        if (response.success === "Payment_Pending" || response.success === true) {
          if (purchaseTotal) {
            clientSecret(response.checkout?.client_secret);
            dispatch({
              type: PAYMENT_METHOD,
              payload: {
                showPaymentPage: true,
                client: response.checkout?.client_secret,
                errors: "",
              },
            });
            dispatch({
              type: "EVENT_DETAILS",
              payload: {
                eventType: eventDetails.eventType,
                eventName: eventDetails.name,
                eventDate: eventDetails?.exam_date,
                eventImage: eventDetails?.logo,
              },
            });
            navigate("/checkout", {
              state: {
                from: edit ? "editGrade" : "gradeReg",
                paymentDetails: {
                  showPaymentPage: true,
                  client: response.checkout?.client_secret,
                  errors: "",
                },
                eventDetails: {
                  eventType: eventDetails.eventType,
                  eventName: eventDetails.name,
                  eventDate: eventDetails?.exam_date,
                  eventImage: eventDetails?.logo,
                },
              },
            });
          } else {
            edit
              ? navigate("/parent/grade/edit-register/payment/success", {
                state: {
                  from: edit ? "editGrade" : "gradeReg",
                  name: eventDetails?.name,
                },
              })
              : navigate("/parent/grade/register/payment/success", {
                state: {
                  from: edit ? "editGrade" : "gradeReg",
                  name: eventDetails?.name,
                },
              });
          }
        }
      } catch (error) {
        errormsg(error.messages);
        dispatch({
          type: PAYMENT_METHOD,
          payload: {
            showPaymentPage: true,
            client: "",
            errors: error.messages,
          },
        });
      }
    }
  };

  useEffect(() => {
    let amount = selectedGrades?.reduce(
      (res, cur) => res + (cur?.dancerTotal || 0),
      0
    );

    let count = 0;
    let newItemSelected = false;

     selectedGrades?.forEach((item) => {
      if (item) {
        item?.grades?.forEach((item) => {
          count += 1;
          if (item && !previousCompetition?.includes(item._id))
            newItemSelected = true;
        });
      }
    });

    if (amount > eventDetails?.fees[0]?.familymax && !edit) {
      // family max will not impact edit flow
      setSubTotal(amount)
      amount = eventDetails?.fees[0]?.familymax;
    } else setSubTotal(0);

    if (!edit) {
      amount = amount + familyFee;
      let serviceAmount = eventDetails?.registered ? getEditServiceFee(amount) : getServiceFee(amount);

      setServiceFee(serviceAmount);

      amount = amount + serviceAmount;
    } else {
      const newTotal = amount - prevTotal;
      let newServiceFee = newTotal <= 0 ? 0 : getEditServiceFee(newTotal);
      setServiceFee(newServiceFee);
      amount = newTotal + newServiceFee;
      amount = amount <= 0 ? 0 : amount
    }

    if (count > 0) setPurchaseTotal(amount);
    else setPurchaseTotal(0);

    if (!newItemSelected) {
      if (count !== previousCompetition?.length && count > 0)
        setEnableButton(true);
      else setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  });

  return (
    <div className={org ? "d-flex flex-column flex-grow-1 offset-1 " : "col-lg-7 d-flex flex-column flex-grow-1 offset-1 col-10 "}>
      <div className="flex-grow-1">
        <h5
          className="mt-5 pb-2 mb-3"
          style={{ borderBottom: "1px solid #DDD3D0 ", color: "#424242" }}
        >
          Register in:
        </h5>
        {selectedGrades?.map((dancer, index) => {
          if (dancer?.grades?.length) {
            return (
              <>
                <div className="d-flex justify-content-between align-items-center py-2 my-2">
                  <div className="dancerNameHeading">{dancer?.dancer_name}</div>
                  <div className="dancerFeeBtn">$ {dancer?.dancerTotal}</div>
                </div>
                {dancer?.grades?.map((grade) => {
                  return (
                    <div className="align-items-center d-flex px-2 justify-content-between mt-2">
                      <div className="d-flex">
                        <img
                          src={deleteIcon}
                          style={{ cursor: "pointer" }}
                          alt=""
                          onClick={() =>
                            removeGrade(
                              index,
                              dancer.dancer_name,
                              dancer.dancer_id,
                              grade._id
                            )
                          }
                        />
                        <div className="mx-5 reg-in-det">
                          {grade?.grade_name}
                        </div>
                      </div>
                      <div className="reg-in-det">$ {grade?.grade_fee}</div>
                    </div>
                  );
                })}
              </>
            );
          }
        })}
        <div>
          {!edit && (prevFamilyFee !== undefined || familyFee !== 0) && (
            <div className="d-flex justify-content-between align-items-center">
              <div className=" my-3c reg-name-font">Family</div>
              <div className=" my-3 reg-amt">
                ${prevFamilyFee ? prevFamilyFee : familyFee}
              </div>
            </div>
          )}
          {serviceFee > 0 && <div className="d-flex justify-content-between align-items-center">
            <div className=" my-3 reg-name-font">Service</div>
            <div className=" my-3 reg-amt">
              $
              {serviceFee}
            </div>
          </div>}
        </div>
      </div>
      <hr />
      {subTotal && selectedGrades?.length > 0 ? (
        <div className="d-flex justify-content-between">
          <div className=" my-3" style={{ fontSize: "30px", color: "#424242" }}>
            SubTotal:
          </div>
          <div className=" my-3 reg-total">${subTotal}</div>
        </div>
      ) : null}
      <div className="d-flex justify-content-between">
        <div className=" my-3" style={{ fontSize: "30px", color: "#424242" }}>
          Total:
        </div>
        <div className=" my-3 reg-total">
          ${purchaseTotal && selectedGrades?.length > 0 ? purchaseTotal : 0}
        </div>
      </div>
      {!org && <div>
        <button
          className={
            enableButton && selectedGrades?.length > 0
              ? "purchase-btn mb-5"
              : "purchase-btn-disabled mb-5"
          }
          disabled={!(enableButton && selectedGrades?.length > 0)}
          onClick={paymentButtonHandler}
        >
          Continue to payment
        </button>
      </div>}
    </div>
  );
}
