import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getCSVData } from './scheduleHelpers';

export const exportToExcel = async (tableData, name) => {
    try {
        // Retrieve data
        const data = getCSVData(tableData);
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Schedule');

        // Define header styles
        const headerStyle = {
            font: { bold: true },
            alignment: { horizontal: 'center' },
            border: {
                bottom: { style: 'thin' }
            }
        };
        const generalAlignmentStyle = {
            alignment: { horizontal: 'center' },
        }

        // Define column headers
        worksheet.columns = [
            { header: 'Stage Name', width: 20 },
            { header: 'Comp Code', width: 15 },
            { header: 'Dancer Count', width: 15 },
            { header: 'Timing', width: 30 }
        ];

        // Add stage headers
        let currentColumn = 1;
        data.forEach((stage) => {
            worksheet.getCell(1, currentColumn).value = stage.stageName;
            worksheet.getCell(1, currentColumn).style = headerStyle;
            worksheet.mergeCells(1, currentColumn, 1, currentColumn + 2);

            currentColumn += 3;
        });

        // Add data for each stage
        let maxRows = 1;
        let currentColumnForData = 1;
        data.forEach((stage) => {
            let rowIndex = 2;

            stage.timings.forEach((timing) => {
                worksheet.getCell(rowIndex, currentColumnForData).value = timing.compCode;
                worksheet.getCell(rowIndex, currentColumnForData + 1).value = timing.dancerCount;
                worksheet.getCell(rowIndex, currentColumnForData + 2).value = `${timing.startTime} - ${timing.endTime}`;
                rowIndex += 1;
                maxRows = Math.max(maxRows, rowIndex);
            });

            currentColumnForData += 3;

        });



        // Adjust column widths if needed
        worksheet.columns.forEach((col, index) => {
            col.width = col.width = (index + 1) % 3 === 0 ? 20 : 10;
            col.eachCell({ includeEmpty: true }, (cell) => {
                cell.style = generalAlignmentStyle; // Apply center alignment to each cell
            });
        });

        // Adjust row heights based on the content
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber <= maxRows) {
                row.height = 20;
            }
        });

        // Save file
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), name);
    } catch (error) {
        console.error('Error exporting to Excel:', error);
    }
};


