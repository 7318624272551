import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import closeIcon from '../../../../../Assets/close-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';
import Loader from '../../../../Loader/Loader';
import { serviceConsumer } from '../../../../../network/ServiceConsumer';
import MainDiv from '../../../../AddedAdjdiv/MainDiv';
import Box from '@mui/material/Box';
import AddButton from '../../../../AddButton/AddButton';
import './CollaboratorModal.css';

const CollaboratorModal = ({ modalState, title, collaborator, btnName }) => {
  const [loading, setLoading] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modalReducer);
  const [errDelay, setErrDelay] = useState(false);
  const [err, setErr] = useState("")
  const eventId = modal.eventId;
  const ManageType = localStorage.getItem('Manage');

  //load the collaborators on page load
  const getCollaborators = async () => {
    try {
      const feisUrl = `${process.env.REACT_APP_BASE_URL}/people/${eventId}/${collaborator}`
      const gradeUrl = `${process.env.REACT_APP_BASE_URL}/grade-exam/${eventId}/${collaborator}`
      const url = ManageType === "feis" ? feisUrl : gradeUrl
      setErrDelay(true);
      setLoading(true);
      const response = await serviceConsumer('GET', url);
      setCollaborators(response.people);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErr(error.messages)
    }
  };
  useEffect(() => {
    getCollaborators();
  }, []);

  //open modal to select old collaborator-->selectOldCollaboratorModal
  const openSelectOldCollaboratorModal = () => {
    if (title !== "Sponsors") {
      dispatch({
        type: SET_MODAL,
        payload: {
          modal: `SelectOld${title}`,
          eventId: eventId,
        },
      });
    } else {
      dispatch({
        type: SET_MODAL,
        payload: {
          modal: `NewSponsors`,
          eventId: eventId,
        },
      });
    }
  };

  //to close any modal
  const modalCloseHandler = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        modal: '',
        eventId: '',
      },
    });
  };

  //delete handler
  const deleteHandler = async (collaborator) => {
    try {
      const feisUrl = `${process.env.REACT_APP_BASE_URL}/people/${collaborator._id}/delete/${eventId}`;
      const gradeUrl = `${process.env.REACT_APP_BASE_URL}/people/${collaborator._id}/deletegrade/${eventId}`;
      const url = ManageType === "feis" ? feisUrl : gradeUrl
      setLoading(true);
      const response = await serviceConsumer('DELETE', url);
      getCollaborators();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErr(error.messages)
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal className="addDancerScroll" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} open={modalState}>
        <Box className="mui-box">
          <div className="modal-box">
            <div className="collaborator-modal">
              <div className="close-div mt-4 mb-1">
                <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
              </div>
              <p className="color-subtitle text-center mb-4"> {title}</p>
              <div className="d-flex align-items-center justify-content-center" style={{ background: 'white,borderRadius: 14px;' }}>
                <div>
                  {collaborators.map((item) => {
                    return (
                      <MainDiv
                        name={item.name}
                        location={item.location}
                        photo={item.photo}
                        collaboratorModal={true}
                        onDelete={() => deleteHandler(item)}
                      />
                    );
                  })}
                </div>
                {!loading && !err && collaborators.length === 0 && errDelay && (
                  <div style={{ color: 'red' }}>There are no {collaborator} in this event</div>
                )}
              </div>
              {!loading && err && (<div style={{ color: 'red', textAlign: "center" }}> {err}</div>)}
              <div className="d-flex justify-content-center mb-4 ">
                <AddButton className="plusIconbtn"onClick={openSelectOldCollaboratorModal} from="collab">{btnName}</AddButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CollaboratorModal;
