import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeIcon from "../../Assets/close-icon.svg";
import "./AddDancerModal.css";
import FormikControl from "../formik/FormikControl";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import axios from "axios";

const AddDancerModal = ({ modalState, action, UpdateDancer }) => {
  const [schools, setSchools] = useState([])
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);

  //config for api calls
  const config = {
    headers: {
      Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
      accesstoken: localStorage.getItem("accessToken"),
    },
  };

  // select-options for form
  const genderData = [
    {
      key: "Boys/Men",
      value: "Boys/Men",
    },
    {
      key: "Girls/Ladies",
      value: "Girls/Ladies",
    },
  ];
  const levelData = [
    {
      key: "Beginner",
      value: "Beginner",
    },
    {
      key: "Advanced Beginner",
      value: "Advanced Beginner",
    },
    {
      key: "Novice",
      value: "Novice",
    },
    {
      key: "Prizewinner",
      value: "Prizewinner",
    },
    {
      key: "Preliminary Championships",
      value: "Preliminary Championships",
    },
    {
      key: "Open Championships",
      value: "Open Championships",
    },
  ];
  const schoolData = schools?.map(school => { return { key: school?.schoolName, value: school?.schoolName } })

  //getting school data from api
  const getSchoolsList = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/account/getSchools`;
    try {
      const response = await axios.get(url, config);
      setSchools(response?.data?.result)
    }
    catch (error) {
    }
  }
  useEffect(() => {
    getSchoolsList()
  }, [])


  //Initial values for formik
  const addDancerInitialValues = {
    first_name: "",
    last_name: "",
    date_birth: "",
    gender: "",
    school: "",
    levels: "",
  };

  //validation schema for formik
  const addDancerValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .label("First Name:")
      .required("This field is required.")
      .nullable()
      .max(30, "Maximum characters allowed 30"),
    last_name: Yup.string()
      .trim()
      .label("Last Name:")
      .required("This field is required.")
      .nullable()
      .max(30, "Maximum characters allowed 30"),
    date_birth: Yup.number()
      .min(1900, "invalid birth year")
      .test(
        'no-special-characters',
        'invalid birth year',
        value => /^[0-9]+$/.test(value)
      )
      .test('is-four-digit', 'Must be a 4-digit number', (value) => value && value.toString().length === 4)
      .required('This field is required')
      .max(new Date().getFullYear(), "invalid birth year"),
    gender: Yup.string()
      .trim()
      .label("Gender:")
      .required("This field is required.")
      .nullable(),
    school: Yup.string()
      .trim()
      .label("School:")
      .required("This field is required.")
      .nullable(),
    levels: Yup.string()
      .trim()
      .label("Levels:")
      .required("This field is required.")
      .nullable(),
  });

  //formik submit
  const addDancer = async (values, formikHelpers) => {
    const schoolSelected = schools.find(item => item.schoolName === values?.school)
    setLoading(true);
    let requestData = values;
    try {
      if (!values) {
        setValidation(false);
      }
      const firstName = requestData.first_name.charAt(0).toUpperCase() + requestData.first_name.slice(1, requestData.first_name.length);
      const lastName = requestData.last_name.charAt(0).toUpperCase() + requestData.last_name.slice(1, requestData.last_name.length);
      requestData = { ...requestData, first_name: firstName, last_name: lastName, school_id: schoolSelected?._id }
      const url = `${process.env.REACT_APP_BASE_URL}/account/dancers`;

      const response = await axios.post(url, requestData, config);
      if (response?.status === 200) {
        action();
        UpdateDancer(true);
      }

    } catch (error) {
      setLoading(false);
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Modal className="addDancerScroll" sx={{ display: "flex", alignItems: "center", justifyContent: "center", }} open={modalState}>
      <Box>
        <div style={{ maxHeight: "100vh", padding: "5px 100vw", overflow: "scroll", }}>
          <div className="main-div">
            <div className="close-div mt-4 mb-1">
              <img src={closeIcon} alt="close-icon" className="close-icon" onClick={action} />
            </div>
            <p className="color-subtitle text-center mb-4">Add dancer</p>
            <div
              className="d-flex align-items-center justify-content-center addDancermodelLabel"
              style={{ background: "white,borderRadius: 14px;" }}
            >
              <Formik
                initialValues={addDancerInitialValues}
                validationSchema={addDancerValidationSchema}
                onSubmit={addDancer}
              >
                {(formik) => (
                  <Form className="form form-label-right" autoComplete="off">
                    <div className="add-dancer">
                      <div>
                        <FormikControl
                          className="formikInput"
                          control="input"
                          type="text"
                          label="First Name:"
                          name="first_name"
                          placeholder="First Name"
                          id="addDancerinput"
                          maxlength={30}
                        />
                        <FormikControl
                          className="formikInput"
                          control="input"
                          type="text"
                          label="Last Name:"
                          name="last_name"
                          placeholder="Last Name"
                          id="addDancerinput"
                          maxlength={30}
                        />
                        <FormikControl
                          className="formikInput"
                          control="input"
                          type="number"
                          label="Birth Year:"
                          name="date_birth"
                          placeholder="Date of birth"
                          maxlength={4}
                          id="addDancerinput" />
                        <FormikControl
                          className="formikSelect"
                          control="select"
                          name="gender"
                          options={genderData}
                          label="Gender:"
                          showAs="Gender"
                          style={{ color: "black" }}
                          id="addDancerinput"
                          setFieldValue={formik.setFieldValue}
                        />
                        <FormikControl
                          className="formikSelect"
                          control="select"
                          label="School:"
                          showAs="School"
                          name="school"
                          options={schoolData}
                          style={{ color: "black" }}
                          id="addDancerinput"
                          setFieldValue={formik.setFieldValue}
                        />
                        <FormikControl
                          className="formikSelect"
                          control="select"
                          label=" Level:"
                          name="levels"
                          showAs="Level"
                          options={levelData}
                          style={{ color: "black" }}
                          id="addDancerinput"
                          setFieldValue={formik.setFieldValue}
                        />
                        <div className="my-5 d-flex justify-content-center">
                          <button
                            className={formik.isValid && formik.dirty ? "updateChange" : "updateChangeDisabled"}
                            style={{ fontWeight: "500" }}
                            type="submit"
                            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                          >
                            Add Dancer
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddDancerModal;
