import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import HeaderOrg from '../Headers/HeaderOrg';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import MainDiv from '../../Components/AddedAdjdiv/MainDiv';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import AccomodationDiv from '../../Components/AddedAdjdiv/AccomodationDiv';
import Back from '../../Components/Back/Back';
import Help from '../../Components/Help/Help';
import { formatDate } from '../../helper/formatDate';
import GoLiveConfirmationModal from '../../Components/Modals/OrganizerModel/GoLiveConfirmationModal';

const FinalFiezDetails = () => {
  const [loading, setLoading] = useState(false);
  const [adj, setAdj] = useState([]);
  const [eventDetails, setEventDetails] = useState();
  const [adjucator, setAdjucator] = useState([]);
  const [musician, setMusician] = useState([]);
  const [sponsor, setSponser] = useState([]);
  const [accomodation, setaccomodation] = useState([
    eventDetails?.accomodations,
  ]);
  const [apiLoading, setApiLoading] = useState(false)
  const [openGoLiveModal, setOpenGoLiveModal] = useState(false)

  let EventID = localStorage.getItem('ID');
  useEffect(() => {
    if (eventDetails?.sponsors?.length) setSponser(eventDetails?.sponsors);
    if (eventDetails?.examiners?.length) setMusician(eventDetails?.examiners);
  }, []);

  //  to get eventDetails on back
  useEffect(() => {
    setaccomodation(eventDetails?.accomodations);
  }, [eventDetails]);

  const getEventDetails = async () => {
    const promises = [];
    setLoading(true);
    try {
      if (EventID) {
        const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
        const response = await serviceConsumer('GET', url);
        setEventDetails(response.grade_exams);

        if (response.grade_exams) {
          const { adjudicators, examiners, sponsors } = response.grade_exams;
          if (adjudicators?.length > 0) {
            try {
              const adjucatorP = adjudicators.map(async (Adjmul) => {
                const url = `${process.env.REACT_APP_BASE_URL}/people/id/${Adjmul}`;
                const adjudicatorsRes = await serviceConsumer('GET', url);
                return adjudicatorsRes;
              });

              Promise.all(adjucatorP).then((adjudicatorsRes) => {
                setAdjucator(adjudicatorsRes);
              });

              promises.push(adjucatorP);
            } catch (error) { }
          }

          if (examiners?.length > 0) {
            try {
              const musicianP = examiners.map(async (addMus) => {
                if (addMus) {
                  const url = `${process.env.REACT_APP_BASE_URL}/people/id/${addMus}`;
                  const musiciansRes = await serviceConsumer('GET', url);
                  return musiciansRes;
                }
              });

              Promise.all(musicianP).then((musiciansRes) => {
                setMusician(musiciansRes);
              });

              promises.push(musicianP);
            } catch (error) { }
          }

          if (sponsors?.length > 0) {
            try {
              const sponsorP = sponsors.map(async (addMus) => {
                if (addMus) {
                  const url = `${process.env.REACT_APP_BASE_URL}/people/id/${addMus}`;
                  const sponsorRes = await serviceConsumer('GET', url);
                  return sponsorRes;
                }
              });

              Promise.all(sponsorP).then((sponsorRes) => {
                setSponser(sponsorRes);
              });

              promises.push(sponsorP);
            } catch (error) { }
          }
        }
      }

      Promise.all(promises).then(() => setLoading(false));
    } catch (error) {
      setLoading(false);
    }
  };

  const id = localStorage.getItem('ID');
  const navigate = useNavigate();

  const goLiveHandler = async () => {
    try {
      setApiLoading(true)
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/golive `;
      const response = await serviceConsumer('POST', url, { id });
      if (response.success === true) {
        setOpenGoLiveModal(true)
      }
      setApiLoading(false)

    } catch (error) {
      setApiLoading(false)

    }
  };
  const editHandler = (id) => {
    if (!eventDetails.live) localStorage.setItem("redirectFeis", "goLive")
    localStorage.setItem('TAB', id);
    navigate('/organizer-create-grade');
  };

  useEffect(() => {
    getEventDetails();
  }, []);

  const onBackClick = () => {
    navigate('/organizer-dashboard')
  }

  const getDate = (date) => {
    const dateSplit = date?.split('-');
    return new Date(dateSplit[2], dateSplit[0] - 1, dateSplit[1]);
  };

  return (
    <>
      <HeaderOrg />
      {(apiLoading || loading) && <Loader />}
      {openGoLiveModal && <GoLiveConfirmationModal openModal={openGoLiveModal} setOpenModal={setOpenGoLiveModal} from="goLive" />}
      {!loading && (
        <div className="goLiveMainDIv">
          <Container style={{ background: '#FFFFFF', marginBottom: '10px' }}>
            <div className="headingOrg p-0">
              <div className="d-flex backContainer" style={{ color: '#68AD3F', cursor: 'pointer' }} >
                <Back onBackClick={onBackClick} />
                <div className="organizerExam" ></div>
              </div>
            </div>
            <div className="EventDetailss ">
              <div className="finalMainHead">
                <div className="logoImg">
                  <img src={eventDetails?.logo} alt="logo" />
                  <span className="EventNAme">{eventDetails?.name}</span>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div>
                  <span className="ExamNAme p-0">Grade exam</span>
                  <span className="examVenue"> {moment(formatDate(eventDetails?.exam_date)).format('MMMM Do, YYYY')} </span>
                </div>

                <div className="goLiveButton">
                  <button style={{ fontWeight: '500' }} type="submit" onClick={goLiveHandler} >Go Live </button>
                </div>
              </div>
            </div>
          </Container>
          <div className="goLiveMAin"></div>
          <section className="golive-section">
            <div className="container pb-5">
              <div className="row align-items-start ROwLive">
                <div className="col-lg-6 mainDivLive">
                  <span className="eventDetailsBlock">Event Details</span>
                  <BorderColorIcon className="editIcon" onClick={() => { editHandler(1); }} />
                  <div className="eventsHr" style={{ marginBottom: '17px', height: '1.1px' }} ></div>
                  <div className=" abountEventLive">
                    <div className="dummy">
                      <div className="eventDetailsBlock mt-1" style={{ fontSize: '16px' }} > About event</div>

                      <div className="detailsHeading">Name of Exam:</div>
                      <span className="LiveData">{eventDetails?.name}</span>

                      <div className="detailsHeading"> Exam Contact Email:</div>
                      <span className="LiveData">{eventDetails?.contact_email} </span>

                      <div className="detailsHeading">Region:</div>
                      <span className="LiveData">{eventDetails?.region}</span>
                      <br />

                      <div className="eventDetailsBlock mt-2" style={{ fontSize: '16px' }}>Options</div>
                      <div className="detailsHeading">Cap:</div>
                      <span className="LiveData">{eventDetails?.cap?.capType}</span>

                      {eventDetails?.cap?.levelCap?.length > 0 && (
                        <>
                          <div className="detailsHeading">Level Cap:</div>
                          <span className="LiveData">{eventDetails?.cap?.levelCap} </span>
                        </>
                      )}

                      {eventDetails?.cap?.value && (
                        <>
                          <div className="detailsHeading">Value:</div>
                          <span className="LiveData"> {eventDetails?.cap?.value}</span>
                        </>
                      )}

                      {eventDetails?.cap?.grades && eventDetails?.cap?.capType == 'Level Cap' && (
                        <>
                          <div className="detailsHeading">Grades:</div>
                          <div className="sublevel"> (beginner, advanced beginner,novice, prize-winner)</div>
                          <span className="LiveData">{eventDetails?.cap?.grades} </span>
                        </>
                      )}

                      {eventDetails?.cap?.champ && eventDetails?.cap?.capType == 'Level Cap' && (
                        <>
                          <div className="detailsHeading">Champ:</div>
                          <div className="sublevel"> (prelim and open champ)</div>
                          <span className="LiveData"> {eventDetails?.cap?.champ}</span>
                        </>
                      )}
                    </div>

                    <div className="dummy2">
                      <span className="eventDetailsBlock mt-1" style={{ fontSize: '16px', height: '1.1px' }} > Event date </span>

                      <div className="detailsHeading">Exam Date:</div>
                      <span className="LiveData">{moment(formatDate(eventDetails?.exam_date)).format('MM/DD/YYYY')}</span>

                      <div className="detailsHeading">Reg. Open Date:</div>
                      <span className="LiveData">{moment(formatDate(eventDetails?.opening_date)).format('MM/DD/YYYY')}</span>

                      <div className="detailsHeading">Reg. Close Date:</div>
                      <span className="LiveData">{moment(formatDate(eventDetails?.closing_date)).format('MM/DD/YYYY')} </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mainDivLive" id="sndDiv">
                  <span className="eventDetailsBlock">Venue Details</span>
                  <BorderColorIcon className="editIcon" onClick={() => { editHandler(2); }} />
                  <div className="eventsHr"></div>

                  <div className="detailsHeading">Venue Name:</div>
                  <span className="LiveData">{eventDetails?.venue_name}</span>

                  <div className="detailsHeading">Address:</div>
                  <div className="LiveData">{eventDetails?.venue}</div>
                  <div>{eventDetails?.venue_city}</div>
                  <div>{eventDetails?.state}</div>
                  <div>{eventDetails?.country}</div>
                  <div>  {eventDetails?.postal_code} </div>
                </div>
              </div>
              <div className="row align-items-start ROwLive">
                <div className="col-lg-6 mainDivLive">
                  <span className="eventDetailsBlock">About event</span>
                  <BorderColorIcon className="editIcon" onClick={() => { editHandler(6); }} />
                  <div className="eventsHr" style={{ marginBottom: '17px', height: '1.1px' }} ></div>

                  {eventDetails?.logoName && (
                    <>
                      <div className="label-head-org-file mt-4">Logo:</div>
                      <span className="fileShow"> <AttachFileIcon className="attachFile" />{eventDetails?.logoName}</span>
                    </>
                  )}

                  <div className="label-head-org-file mt-4">Syllabus File:</div>
                  <span className="fileShow "> <AttachFileIcon className="attachFile" />{eventDetails?.syllabus_file}</span>

                  {eventDetails?.description && (
                    <>
                      <div className="label-head-org-file mt-4"> Description: </div>
                      <span className="goLiveDes mt-1">{eventDetails?.description}</span>
                    </>
                  )}

                  {eventDetails?.percentageToAward && (
                    <>
                      <div className="detailsHeading">Percentage to award:</div>
                      <span>{eventDetails?.percentageToAward}</span>
                    </>
                  )}

                  {eventDetails?.minimumNumberToAward && (
                    <>
                      <div className="detailsHeading"> Minimum number to award:</div>
                      <span> {eventDetails?.minimumNumberToAward}</span>
                    </>
                  )}
                </div>
                <div className="col-lg-6 mainDivLive" id="sndDiv">
                  <span className="eventDetailsBlock"> Fees</span>
                  <BorderColorIcon className="editIcon" onClick={() => { editHandler(4); }} />
                  <div className="eventsHr" style={{ marginBottom: '17px', height: '1.1px' }}></div>

                  <div className="mainfee">Family Fee and Family Max</div>
                  <div className="detailsHeading">Family Fee:</div>
                  <span className="LiveData">{eventDetails?.fees[0]?.familyfee}</span>

                  <div className="detailsHeading">Family max:</div>
                  <span className="LiveData">{eventDetails?.fees[0]?.familymax}</span>

                  <div className="mainfee" style={{ marginBottom: '10px' }}> Early and Late Fees </div>
                  <div className="d-flex justify-content-space-between latefee">
                    <div>
                      <span className="mainfee" style={{ marginTop: '10px' }} > Early fee:</span>
                    </div>
                    <div>
                      <span className="mainfee" style={{ marginTop: '10px' }} > Late fee </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-space-between latefee">
                    <div>
                      <div>
                        <div className="detailsHeading">Family Less:</div>
                        <span className="LiveData"> {eventDetails?.fees[0]?.earlyFee?.familyLess}</span>
                      </div>

                      <div className="detailsHeading">If date is before:</div>
                      <div>
                        {eventDetails?.fees[0]?.earlyFee?.ifDateIsBefore && (
                          <span className="LiveData">{moment(formatDate(eventDetails?.fees[0]?.earlyFee?.ifDateIsBefore)).format('MM/DD/YYYY')}</span>
                        )}
                      </div>
                    </div>

                    <div>
                      <div className="detailsHeading">Additional:</div>
                      <span className="LiveData"> {eventDetails?.fees[0]?.lateFee?.additionalFee}</span>

                      <div className="detailsHeading">Late Fee After Date : </div>
                      {eventDetails?.fees[0]?.lateFee?.afterDate && (
                        <span className="LiveData">{moment(formatDate(eventDetails?.fees[0]?.lateFee?.afterDate)).format('MM/DD/YYYY')}</span>
                      )}
                    </div>
                  </div>

                  <div className="mainfee" style={{ marginTop: '10px' }}> {' '} Late Fee After Closed </div>
                  <div className="d-flex justify-content-space-between latefee">
                    <div>
                      <div>
                        <div className="detailsHeading">Additional:</div>
                        <span className="LiveData"> {eventDetails?.fees[0]?.lateFeeAfterClosed?.additionalFee}</span>

                      </div>
                      <div className="detailsHeading">Late fee after closed Registration : </div>
                      <span className="LiveData">{moment(formatDate(eventDetails?.closing_date)).format('MM/DD/YYYY')} </span>
                    </div>
                  </div>
                </div>
              </div>
              {adjucator?.length > 0 || sponsor?.length > 0 || accomodation?.length > 0 ? (
                <div style={{ paddingBottom: '3rem' }}>
                  <>
                    <div className="col-lg-6 mainDivLive ">
                      <span className="eventDetailsBlock">Complete Collaborators</span>
                      <BorderColorIcon className="editIcon" onClick={() => { editHandler(7); }} />
                      <div className="eventsHr" style={{ marginBottom: '17px' }}></div>{' '}

                      {adjucator?.length > 0 && (
                        <div>
                          <span className="detailsHeading  d-grid"> Adjucators:</span>
                        </div>
                      )}

                      {adjucator?.length > 0 &&
                        adjucator.map((adjucators, index) => {
                          return (
                            <>
                              <div>
                                <MainDiv
                                  name={adjucators?.person?.name}
                                  location={adjucators?.person?.location}
                                  state={adjucators?.person?.state}
                                  index={index}
                                  photo={adjucators?.person?.photo}
                                  from={'golive'}
                                />
                              </div>
                            </>
                          );
                        })}
                      {musician?.length > 0 && (
                        <div>
                          <span className="detailsHeading  d-grid">
                            Examiners:
                          </span>
                        </div>
                      )}
                      {musician?.length > 0 &&
                        musician.map((musician, index) => {
                          return (
                            <>
                              <div>
                                <MainDiv
                                  name={musician?.person?.name}
                                  location={musician?.person?.location}
                                  state={musician?.person?.state}
                                  index={index}
                                  photo={musician?.person?.photo}
                                  from={'golive'}
                                />
                              </div>
                            </>
                          );
                        })}{' '}
                      {accomodation?.length > 0 && (
                        <div>
                          <span className="detailsHeading  d-grid">
                            Accomdations:
                          </span>
                        </div>
                      )}
                      {accomodation?.length > 0 &&
                        accomodation.map((accomodation, index) => {
                          return (
                            <>
                              <AccomodationDiv
                                name={accomodation?.name}
                                location={accomodation?.location}
                                index={index}
                                photo={accomodation?.photo}
                                city={accomodation?.city}
                                country={accomodation?.country}
                                state={accomodation?.state}
                                setSponser={setSponser}
                                from={'golive'}
                                id={accomodation?._id}
                              />
                            </>
                          );
                        })}
                      {sponsor?.length > 0 && (
                        <div>
                          <span className="detailsHeading  d-grid">
                            Sponsors:
                          </span>
                        </div>
                      )}
                      {sponsor?.length > 0 &&
                        sponsor.map((sponser, index) => {
                          return (
                            <>
                              <div>
                                <MainDiv
                                  name={sponser?.person?.name}
                                  location={sponser?.person?.location}
                                  state={sponser?.person?.state}
                                  index={index}
                                  photo={sponser?.person?.photo}
                                  from={'golive'}
                                />
                              </div>
                            </>
                          );
                        })}{' '}
                    </div>
                  </>
                </div>
              ) : (
                ''
              )}
            </div>
          </section>
        </div>
      )}

      <Help />
    </>
  );
};

export default FinalFiezDetails;
